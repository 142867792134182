<template>
  <div class="main">
    <div id="printPage" class="box">
      <div
        style="text-align: center; margin: 20px 0;font-size:30px;color: #444444;line-height:65px;margin-bottom:20px;position:relative;">
        {{
          finalUserInfo.BatchName || finalUserInfo.userSelected &&
          finalUserInfo.userSelected.BatchName
        }}志愿表（{{ finalUserInfo.ExamProvinceName || finalUserInfo.userSelectProvinceName }}）
      </div>
      <div class="print-box">
        <span>{{ headName }}</span>
        <el-button :class="{'close-print':closePrint}" @click="printPage" type="primary" size="mini" plain>打印</el-button>
      </div>
      <div style="background: #eaeaea; color: #555; line-height: 35px; height: 35px; font-size: 16px; padding-left: 10px;">
        <span class="ml10">成绩：<span
          class="wishUserInfo">{{ finalUserInfo.Score || finalUserInfo.userScore }}</span> 分</span>

        <span class="ml10">线差：<span class="wishUserInfo">{{ finalUserInfo.ScoreDiff || ScoreDiff }}</span></span>

        <span class="ml10">科类：<span class="wishUserInfo">{{
            {1: ' 文科', 2: '理科'}[finalUserInfo.SubjectType] || {
              1: '文科',
              2: '理科'
            }[finalUserInfo.userSubjectType]
          }}</span></span>

        <span class="ml10">您的位次：<span class="wishUserInfo">{{ finalUserInfo.RankLow || userRank }}</span></span>
      </div>
      <template>
        <el-table
          :border="false"
          :data="finalTableData"
          style="width: 100%">
          <el-table-column
            align="center"
            label="院校信息">
            <el-table-column
              align="center"
              width="80"
              label="院校志愿">
              <template slot-scope="props">
                <el-tag type="danger">{{ props.row.WishCode }}志愿</el-tag>
              </template>

            </el-table-column>
            <el-table-column
              width="90"
              align="center"
              prop="SchoolCode"
              label="院校代码">

            </el-table-column>
            <el-table-column
              width="150"
              align="center"
              label="院校名称">
              <template slot-scope="props">
                <div class="school-name">{{ props.row.SchoolName }}</div>

                <div v-if="finalUserInfo.WishType === 0 && props.row.EnrollmentPercent" class="special-item">
                    <span><span class="tag-title">录取概率</span>：<span
                      class="tag-content">{{ props.row.EnrollmentPercent }}%</span></span>
                </div>
                <div v-if="finalUserInfo.WishType === 1 && props.row.EnrollmentPercent" class="special-item">
                    <span><span class="tag-title">预测分</span>：<span
                      class="tag-content">{{ props.row.EnrollmentPercent }}</span></span>
                </div>
                <div v-if="props.row.Ranking">
                  <el-tag size="mini">{{ props.row.Ranking + '[' + props.row.RankingType + ']' }}</el-tag>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            align="center"
            width=""
            label="专业信息">
            <el-table-column
              align="center"
              
              label="详情展示">
              <template slot-scope="props">
                <div class="special-content" v-for="(item,index) in props.row.WishSpecialtyList" :key="index">
                  <div class="special-item">
                    <span><span class="tag-title">专业序号</span>：<span
                      class="tag-code">{{ item.Sort || index + 1 }}</span></span>
                  </div>
                  <div class="special-item">
                    <span><span class="tag-title">专业代码</span>：<span class="tag-code">{{ item.Code }}</span></span>
                  </div>
                  <div class="special-item">
                    <span><span class="tag-title">专业名称</span>：<span
                      class="tag-content">{{ item.SpecialtyName }}</span></span>
                    <div>
                      <el-tag v-if="item.LearnSubjectAssessment" size="small">学科评估：{{
                          item.LearnSubjectAssessment
                        }}
                      </el-tag>
                      <el-tag v-if="item.SpecialtyRank" size="small">专业排名：{{ item.SpecialtyRank }}</el-tag>
                    </div>
                  </div>
                  <div v-if="finalUserInfo.WishType === 0 && item.EnrollmentPercent" class="special-item">
                    <span><span class="tag-title">录取概率</span>：<span
                      class="tag-content">{{ item.EnrollmentPercent }}%</span></span>
                  </div>
                  <div v-if="finalUserInfo.WishType === 1 && item.EnrollmentPercent" class="special-item">
                    <span><span class="tag-title">预测分</span>：<span
                      class="tag-content">{{ item.EnrollmentPercent }}</span></span>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            align="center"
            label="调剂意愿">
            <el-table-column
              align="center"
              width="90"
              label="是否服从">
              <template slot-scope="props">
                {{
                  {true: '服从', false: '不服从'}[props.row.IsTransfer] ||
                  {1: '服从', 2: '不服从'}[props.row.Obey]
                }}
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </template>
      <div class="clearfix"></div>
      <!-- <div class="signature">
        <div>专家确认签字：</div>
        <div>日期：</div>
        <div>客户确认签字：</div>
        <div>日期：</div>
      </div> -->
      <div class="fillin-instruction mt120 mb20">
        <p class="footer-notes"><span class="footer-notes-tag">注</span>：以上志愿表为模拟填报志愿表，正式填报请登录省考试院指定填报网站。</p>
        <p class="footer-apply">
          申明：使用当年招生计划和参考历年录取数据的志愿模拟填报平台，参考数据均来自省考试院，正式填报过程中如遇到数据错误，请参阅省考试院发布的相关招生计划书籍。
        </p>
      </div>
    </div>
  </div>
  
</template>
<script>

import API from '@/api/config'
// import {WishDetail} from '@/api/newEntrance'

export default {
  name: "wish-table",
  data () {
    return {
      closePrint: false,
      finalTableData: [],
      finalUserInfo: {},
      userRank: '',
      ScoreDiff: '',

      Name: '',
      MemberName: '',
      headName: ''
    }
  },
  mounted () {
    this.init()
    /*setTimeout(() => {
      this.printPage()
    }, 1200)*/
  },
  created () {
    
  },

  beforeDestroy () {
    // this.$store.state.settings.hideHeader = false
  },

  computed: {},

  methods: {

    init () {
      this.$getStorage("userData").then(res => {
        this.headName = res.NickName
      })

      if (this.$route.query.WishId) {
        API.User.WishDetail({
          WishId: this.$route.query.WishId
        }).then(res => {
          this.finalUserInfo = res.data
          this.finalTableData = this.finalUserInfo.WishSchoolList
        })
      } else {
        this.finalTableData = JSON.parse(localStorage.getItem('finalWishTable'))
        this.finalUserInfo = JSON.parse(localStorage.getItem('userBatchList')) || '-'
        this.userRank = JSON.parse(localStorage.getItem('userRank'))
        this.ScoreDiff = JSON.parse(localStorage.getItem('ScoreDiff'))
      }
    },
    printPage () {
      this.closePrint = true
      setTimeout(() => {
        window.print();
        this.closePrint = false
      }, 0)
    }
  }
}
</script>

<style scoped lang="less">

.main {
  height: 100%;

  overflow: auto;

}
.box {
  width: 1060px;

  box-sizing: border-box;
  margin: 0 auto;
  padding-top: 2rem;
  position: relative;
}

.el-table {
  margin: 0;
}

.print-table .college-info {
  text-align: left;
  padding: 15px;
  font-size: 22px !important;
  line-height: 30px;
}

.wishUserInfo {
  color: rgba(var(--themecolor),1);
}

.print-table p {
  font-size: 16px;
}

.print-table .majors-list {
  text-align: left;
  padding: 5px 10px;
}

.school-name {
  font-size: 18px;
  color: rgba(var(--themecolor),1);
}

.school-wish {

}

.special-content {
  width: 100%;
  display: flex;
  border-bottom: 1px solid rgba(98, 193, 255, 0.68);
  padding-top: 1rem;

  .special-item {
    width: 20%;
    text-align: left;
    margin-left: 1rem;

    .tag-title {
      color: #666666;
    }

    .tag-code {
      color: #4484f5;
      font-size: 15px;
    }

    .tag-content {
      color: rgba(var(--themecolor),1);
      font-size: 15px;
    }
  }

  .special-item:last-child {
    width: auto;
  }
}
.fillin-instruction {
  margin-bottom: 40px;
}
.footer-notes {
  font-size: 16px;
  color: #4484f5;

  .footer-notes-tag {
    color: var(--color-red);
    font-size: 16px;
  }
}

.footer-apply {
  font-size: 15px;
  color: #444444;
}

.mt120 {
  // margin-top: 120px;
}

.print-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  & > span {
    font-size: 20px;
  }
}

.close-print {
  display: none;
}

.signature {
  margin: 50px 0 20px;
  display: flex;
  justify-content: space-between;

  div {
    width: 25%;
  }
}
</style>
